/* Tags */

h4 {
  margin-bottom: 0;
  margin-top: var(--pico-border-radius);
}

body {
  padding: var(--pico-spacing);
  overflow: hidden;
  height: 100vh;
}

nav {
  margin-bottom: var(--pico-spacing);
  position: relative;
}

nav > img {
  height: 48px;
}

nav > details {
  position: absolute!important;
  right: 0;
}

h1 {
  font-size: 5rem;
  text-align: center;
  width: 100%;
}

/* Camera */

.camera {
  border-radius: var(--pico-border-radius);
  background-color: black;
  padding-bottom: 100%;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 0;
}

.camera > div {
  transform: translate(-50%, -50%);
  position: absolute;
  width: 100%;
  left: 50%;
  top: 50%;
}

/* Info */

.info {
  text-align: center;
}

/* Overlay */

.overlay {
  background-color: #000000AA;
  transition-duration: 300ms;
  pointer-events: none;
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
  z-index: 1;
  left: 0;
  top: 0;
}

.overlay.show {
  pointer-events: all;
  opacity: 1;
}

/* Reservation */

.reservation {
  transition-duration: 300ms;
  position: absolute;
  bottom: -75%;
  height: 75%;
  width: 100%;
  z-index: 2;
  left: 0;
}

.reservation.show {
  bottom: 0;
}

.reservation > article {
  flex-direction: column;
  display: flex;
  height: 100%;
}

/* Utility */

.grid {
  display: grid;
  gap: var(--pico-nav-element-spacing-horizontal)
}

.grid > hr {
  margin-bottom: 0;
  margin-top: var(--pico-nav-element-spacing-horizontal);
}

.grid-2 {
  grid-template-columns: repeat(2, 1fr);
}

.flex-center {
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 1!important;
}

.error {
  color: red;
}

.success {
  color: green;
}